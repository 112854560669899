import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleUser } from "../../shopApi/useProduct";
import Loader from "../../components/Loader";
import Sidebar from "../../components/layout/Sidebar";
import { FaAngleLeft } from "react-icons/fa6";
import { useQueryClient } from "@tanstack/react-query";
import Header from "../../components/layout/Header";

const UserDetail = () => {
  const { id } = useParams();
  const { SingleUser, SingleUserLoading, SingleUserRefetch } =
    useGetSingleUser(id);
  const navigate = useNavigate();
  const query = useQueryClient();

  useEffect(() => {
    SingleUserRefetch();
  }, [id]);

  return (
    <Sidebar>
      <Header />

      {SingleUserLoading ? (
        <Loader />
      ) : (
        <div className="p-6">
          <div
            className="w-8 h-8 mb-4 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
            onClick={() => {
              navigate(-1);
              query.clear();
            }}
          >
            <FaAngleLeft />
          </div>
          <h2 className="text-2xl font-bold mb-4">User Details</h2>
          <div className="grid grid-cols-2">
            <div>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">First Name:</span>{" "}
                {SingleUser?.fname}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Last Name:</span>{" "}
                {SingleUser?.lname}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Bio:</span>{" "}
                {SingleUser?.bio}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Email: </span>{" "}
                {SingleUser?.email ? SingleUser?.email : "NAN"}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Email verified: </span>{" "}
                {SingleUser?.email === true ? "verified" : "Unverified"}
              </p>
              <p className="text-lg mb-2  text-gray-500">
                <span className="font-medium text-black">Role:</span>{" "}
                {SingleUser?.role}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Gender:</span>{" "}
                {SingleUser?.gender}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Height:</span>{" "}
                {SingleUser?.height_in_cm}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Weight:</span>{" "}
                {SingleUser?.weight_in_kg}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Foot Number:</span>{" "}
                {SingleUser?.foot_number}
              </p>
              <p className="text-lg mb-2 text-gray-500">
                <span className="font-medium text-black">Preferred hand or foot:</span>{" "}
                {SingleUser?.preferred_hand_or_foot}
              </p>
             
            </div>
            <div>
              <p className="text-lg mb-2">Profile Image:</p>
              {SingleUser?.profile_image ? (
                <img
                  src={SingleUser?.profile_image.url}
                  alt={SingleUser?.profile_image.name}
                  className="w-48 h-48 rounded-full mb-4"
                />
              ) : (
                "NAN"
              )}
            </div>
          </div>
       
        </div>
      )}
    </Sidebar>
  );
};

export default UserDetail;
