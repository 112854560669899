import React, { memo, useEffect } from "react";
import CustomButton from "./CustomButton";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";

const CustomPagination = ({ page, limit, totalPages, setPage }) => {
  const handleBackButtonClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  const handlePageClick = (newPage) => {
    setPage(newPage);
  };
  return (
    <div className="flex gap-3 items-center justify-between mt-3">
      <div>
        show pages {page} - {totalPages}
      </div>
      <div className="flex gap-3 items-center justify-between">
        <CustomButton
          disabled={page <= 1}
          btntext={<TfiAngleLeft />}
          bg={page <= 1 && "bg-white"}  textColor={page <= 1 && "text-black"}
          border={page <= 1 && "border"}
          padding="0"
          rounded="rounded-lg"
          addclass="w-9 h-9 "
          handleClick={handleBackButtonClick}
        />
    
          <div
          className={`w-9 h-9 flex justify-center items-center ${
            page === page ? "bg-customBlue text-white" : "bg-gray-200"
          } rounded-lg cursor-pointer`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </div>
        {page < totalPages && (
          <div
            className={`w-9 h-9 flex justify-center items-center bg-gray-200 rounded-lg cursor-pointer`}
            onClick={() => handlePageClick(page + 1)}
          >
            {page + 1}
          </div>
        )}
        <CustomButton
          disabled={page >= totalPages}
          rounded="rounded-lg"
          padding="0"
          addclass="w-9 h-9 " textColor={page >= totalPages && "text-black"}
          btntext={<TfiAngleRight />} border={page >= totalPages && "border"}
          bg={page >= totalPages && "bg-white"}
          handleClick={handleNextButtonClick}
        />
      </div>
    </div>
  );
};

export default memo(CustomPagination);
