import React from 'react'

const Forbidden = () => {
  return (
    <main className='h-screen flex justify-center items-center text-center bg-gray-100'>
      <section>
        <h1 className='text-5xl font-bold text-customBlue'>404</h1>
        <h2 className='text-5xl font-bold text-blue-700'>Page Not Found</h2>
        <p className='mt-4 text-lg text-gray-600'>The page you are looking for does not exist.</p>
      </section>
    </main>
  )
}

export default Forbidden
