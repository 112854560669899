// yourSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
  userData :null
};

const UserSlice = createSlice({
  name: 'yourSlice',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    setUserData :(state , payload)=>{
        state.value = payload.value
    }
  },
});

export const { increment, decrement, setUserData } = UserSlice.actions;
export default UserSlice.reducer;
