import Swal from "sweetalert2";

const useConfirmToast = () => {
  const showAlert = ({
    title = "Are you sure?",
    text = "You won't be able to revert this!",
    icon = "warning",
    confirmButtonText = "Yes, delete it!",
    cancelButtonText = "No, cancel!",
    reverseButtons = true,
    onConfirm,
    onCancel,
    confirmText = "Deleted!",
    confirmTextMessage = "Your file has been deleted.",
    cancelText = "Cancelled",
    // cancelTextMessage = "Your imaginary file is safe :)"
  }) => {
    Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons,
      customClass: {
        confirmButton: " bg-blue-500 p-2 text-white me-2 rounded-lg",
        cancelButton: " bg-red-500 p-2 text-white rounded-lg ms-2"
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: confirmText,
          text: confirmTextMessage,
          icon: "success",
          customClass: {
            confirmButton: "bg-red-500 p-2 text-white rounded-lg w-32"
          },
          buttonsStyling: false
        });
        if (onConfirm) onConfirm();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: cancelText,
          // text: cancelTextMessage,
          icon: "error",
          customClass: {
            confirmButton: "bg-red-500 p-2 text-white rounded-lg w-32"
          },
          buttonsStyling: false
        });
        if (onCancel) onCancel();
      }
    });
  };

  return showAlert;
};

export default useConfirmToast;
