import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import Loader from "../../components/Loader";
import useToast from "../../customHook/useToast";
import {
  useAddUserSport,
  useAddUserType,
  useGetSingleUserSport,
  useGetSport,
  useUserSport,
  useUserTypes,
} from "../../shopApi/useProduct";

const AddUserSport = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  // const { id } = useParams();
  // const { SingleUser, SingleUserLoading, SingleUserRefetch } =
  //   useGetSingleUser(id);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { mutate: AddUser, isPending: UserPending } = useAddUserSport();
  const { UserTypes, UserTypesLoading, UserTypesRefetch } = useUserTypes();
  const { Sport, SportRefetch, SportLoading } = useGetSport({
    page: 1,
    limit: 100,
  });
  useEffect(() => {
    UserTypesRefetch();
    SportRefetch();
  }, []);

  const showToast = useToast();
  // edit user
  const handleAddUserType = async (data) => {
    AddUser(
      {
        userTypeId: parseInt(data.type),
        role: data.role,
        sportId: parseInt(data.sportId),
      },
      {
        onSuccess: (data) => {
          showToast({ status: "success", text: "successfully add" });
          navigate("/userSport");
          query.clear();
        },
        onError: (error) => {
          showToast({ status: "error", text: error.response.data.message });
        },
      }
    );
  };

  return (
    <Sidebar>
      <Header />

      <div>
        <div
          className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
          onClick={() => {
            navigate(-1);
            query.clear();
          }}
        >
          <FaAngleLeft />
        </div>
        <form
          onSubmit={handleSubmit(handleAddUserType)}
          className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
        >
          <div>
            <label className="font-medium text-sm">Sport ID</label>
            <select
              {...register("sportId", {
                required: "sport Id is required",
              })} disabled={SportLoading}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select</option>
              {Sport &&
                Sport?.sports?.map((value, index) => (
                  <option key={index} value={value?.id}>
                    {value?.sport_name}
                  </option>
                ))}
            </select>
            {errors.sportId && (
              <p className="text-red-500 text-sm">{errors.sportId.message}</p>
            )}
          </div>
          <div>
            <label className="font-medium text-sm">User Type</label>
            <select
              {...register("type", {
                required: "Type is required",
                minLength: 1,
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select type</option>
              {UserTypes &&
                UserTypes?.map((value, index) => (
                  <option key={index} value={value?.id}>
                    {value?.type}
                  </option>
                ))}
            </select>
            {errors.type && (
              <p className="text-red-500 text-sm">{errors.type.message}</p>
            )}
          </div>
          <div>
            <label className="font-medium text-sm">Role</label>
            <input
              type="text"
              placeholder="Enter Role"
              {...register("role", { required: "Role is required" })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.role && (
              <p className="text-red-500 text-sm">{errors.role.message}</p>
            )}
          </div>

          <div>
            <CustomButton
              type="submit"
              disabled={UserPending}
              btntext={
                UserPending ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  "Continue"
                )
              }
              addclass="mt-3"
            />
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddUserSport;
