import { useState, useEffect } from 'react';

const useFormatDate = () => {
  const formatDateString = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }
    return '';
  };

  return formatDateString;
};

export default useFormatDate;