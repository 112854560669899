import React, { useState } from "react";
import { FaEnvelope, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/common/CustomButton";
import bg from "../images/login-bg.jpg";
import { useGetMe, useLogin } from "../shopApi/useProduct";
import useToast from "../customHook/useToast";
import { CircularProgress } from "@mui/material";
const Login = () => {
  const { mutate: login, isPending } = useLogin();
  const { MeData, MeLoading, MeRefetch, isSuccess } = useGetMe();
  const showToast = useToast();
  const navigate = useNavigate();

  const [isPassword, setIsPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== "" && email !== "") {
      login(
        {
          email: email,
          password: password,
        },
        {
          onSuccess: async (data) => {
            await handleLoginSuccess(data);
          },
          onError: (error) => {
            showToast({ status: "error", text: "something went wrong" });
          },
        }
      );
    } else {
      showToast({ status: "error", text: "all fields is required" });
    }
  };
  const handleLoginSuccess = async (data) => {
    // localStorage.setItem("access-token", data);
    sessionStorage.setItem("access-token", data);
    try {
      const MeData = await MeRefetch();
      // console.log("MeData:", MeData.data.role);
      if (MeData && MeData.data.role) {
        // console.log("User role:", MeData.data.role);
        if (MeData.data.role === "Admin") {
          navigate("/");
          showToast({ status: "success", text: "Signed in successfully" });
        } else {
          // localStorage.clear();
          sessionStorage.clear();
          
          showToast({
            status: "error",
            text: "You are not logged in. Only admin can log in.",
          });
        }
      } else {
        console.error("Failed to fetch user role");
        showToast({ status: "error", text: "Failed to fetch user role" });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      showToast({ status: "error", text: "Failed to fetch user data" });
    }
  };
  return (
    <div
      className="bg-cover	bg-center	bg-no-repeat"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="lg:w-1/2 md:w-1/2 sm:w-1/2 w-11/12 mx-auto h-screen flex justify-center items-center 	">
        <form
          className=" p-4 shadow border w-full rounded-xl bg-white"
          onSubmit={handleSubmit}
        >
          <div>
            <h4 className="text-2xl font-bold  text-customBlue">Log In</h4>
          </div>
          <div className="flex gap-2 items-center border px-2 my-3 rounded-lg ">
            <span className="input-group-text border-0 ">
              <FaEnvelope size={20} className="text-customBlue" />
            </span>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="focus:outline-none border-0 p-3  ps-0 rounded-lg w-full "
              placeholder="Enter your Email"
            />
          </div>
          <div className="flex gap-2 items-center border px-2 my-3 rounded-lg ">
            <span className="input-group-text border-0 ">
              <MdLock size={20} className="text-customBlue" />
            </span>
            <input
              type={isPassword ? "text" : "password"}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="focus:outline-none border-0 p-3  ps-0 rounded-lg w-full "
              placeholder="Password"
            />
            <span
              className="input-group-text border-0 ps-3"
              onClick={() => setIsPassword(!isPassword)}
            >
              {isPassword ? (
                <FaRegEye size={20} className="text-customBlue" />
              ) : (
                <FaRegEyeSlash size={20} className="text-customBlue" />
              )}
            </span>
          </div>
          <CustomButton
            btntext={
              isPending ? (
                <CircularProgress sx={{ color: "white" }} size={20} />
              ) : (
                "Continue"
              )
            }
            disabled={isPending}
            bg="bg-customBlue"
            rounded="rounded-lg"
            addclass="w-full"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
