import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import useToast from "../../customHook/useToast";
import {
  useAddCategory,
  useGetSingleCategory,
  useGetSport,
  useUpdateCategory,
} from "../../shopApi/useProduct";
import Loader from "../../components/Loader";

const EditCategory = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  const { id } = useParams();
  const { SingleCategory, SingleCategoryLoading, SingleCategoryRefetch } =
    useGetSingleCategory(id);
  const { Sport, SportRefetch, SportLoading } = useGetSport({
    page: 1,
    limit: 100,
  });
  const { mutate: EditCategory, isPending: UserPending } = useUpdateCategory();
  const showToast = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    SingleCategoryRefetch();
    SportRefetch();
  }, [id]);
  useEffect(() => {
    if (SingleCategory && SingleCategory?.id) {
      setValue("name", SingleCategory?.name);
      setValue("description", SingleCategory?.description);
      setValue("sportId", SingleCategory?.sport?.id);
    }
  }, [SingleCategory, Sport]);
  // add user
  const handleAddUserType = async (data) => {
    EditCategory(
      {
        name: data.name,
        sportId: data.sportId,
        id: id,
        ...(data.description && { description: data.description }),
      },
      {
        onSuccess: (data) => {
          showToast({ status: "success", text: "successfully update" });
          navigate("/category");
          query.clear();
        },
        onError: (error) => {
          // if (
          //   error.response.data.message === `The category with name ${data.name} is already exists!`
          // ) {
          //   showToast({ status: "success", text: "successfully add" });
          //   navigate("/category");
          //   query.clear();
          // } else {
          showToast({ status: "success", text: error.response.data.message });
          // }
        },
      }
    );
  };

  return (
    <Sidebar>
      <Header />
      {SingleCategoryLoading ? (
        <Loader />
      ) : (
        <div>
          <div
            className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
            onClick={() => {
              navigate(-1);
              query.clear();
            }}
          >
            <FaAngleLeft />
          </div>
          <form
            onSubmit={handleSubmit(handleAddUserType)}
            className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
          >
            <div className="mt-2">
              <label className="font-medium text-sm">Sport</label>
              <select
                {...register("sportId", {
                  required: "sport id is required",
                })}
                disabled={SportLoading}
                className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
              >
                <option value="">Select</option>
                {Sport &&
                  Sport.sports.map((value, index) => (
                    <option key={index} value={value.id}>
                      {value.sport_name}
                    </option>
                  ))}
              </select>
              {errors.sportId && (
                <p className="text-red-500 text-sm">{errors.sportId.message}</p>
              )}
            </div>
            <div className="mt-2">
              <label className="font-medium text-sm">Name</label>
              <input
                type="text"
                {...register("name", { required: "name is required" })}
                placeholder="name"
                className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name.message}</p>
              )}
            </div>
            <div className="mt-2">
              <label className="font-medium text-sm">
                Description{" "}
                <span className="text-xs text-gray-500">(optional)</span>
              </label>
              <textarea
                rows={4}
                {...register("description")}
                placeholder="description"
                maxLength={1000}
                className="bg-customWhite text-sm p-2.5 rounded-xl resize-none border-0 focus:outline-none w-full"
              ></textarea>
            </div>

            <div>
              <CustomButton
                type="submit"
                disabled={UserPending}
                btntext={
                  UserPending ? (
                    <CircularProgress sx={{ color: "white" }} size={20} />
                  ) : (
                    "Continue"
                  )
                }
                addclass="mt-3"
              />
            </div>
          </form>
        </div>
      )}
    </Sidebar>
  );
};

export default EditCategory;
