import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import Loader from "../../components/Loader";
import useToast from "../../customHook/useToast";
import {
  useAddUserSport,
  useAddUserType,
  useEditUserSport,
  useGetSingleUserSport,
  useGetSport,
  useUserSport,
  useUserTypes,
} from "../../shopApi/useProduct";

const EditUserSport = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  const { id } = useParams();
  const { SingleUserSport, SingleUserSportLoading, SingleUserSportRefetch } =
    useGetSingleUserSport(id);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { Sport, SportRefetch, SportLoading } = useGetSport({
    page: 1,
    limit: 100,
  });
  const { mutate: EditSportRole, isPending: UserPending } = useEditUserSport();
  const { UserTypes, UserTypesLoading, UserTypesRefetch } = useUserTypes();

  useEffect(() => {
    SingleUserSportRefetch();
    UserTypesRefetch();
    SportRefetch();
  }, [id]);

  useEffect(() => {
    if (SingleUserSport && SingleUserSport.id) {
      setValue("type", SingleUserSport?.userType?.id);
      setValue("role", SingleUserSport?.role);
      setValue("sportId", SingleUserSport?.sport?.id);
    }
  }, [SingleUserSport, Sport, UserTypes]);

  const showToast = useToast();
  // edit user
  const handleAddUserType = async (data) => {
    EditSportRole(
      {
        userTypeId: parseInt(data.type),
        role: data.role,
        sportId: parseInt(data.sportId),
        id: id,
      },
      {
        onSuccess: (data) => {
          showToast({ status: "success", text: "successfully update" });
          navigate("/userSport");
          query.clear();
        },
        onError: (error) => {
          // if (
          //   error.response.data.message ===
          //   `The user role: ${data.role} is already Exists!`
          // ) {
          //   showToast({ status: "success", text: "successfully add" });
          //   navigate("/userSport");
          //   query.clear();
          // } else {
          showToast({ status: "success", text: error.response.data.message });
          // }
        },
      }
    );
  };

  return (
    <Sidebar>
      <Header />

      <div>
        <div
          className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
          onClick={() => {
            navigate(-1);
            query.clear();
          }}
        >
          <FaAngleLeft />
        </div>
       {SingleUserSportLoading ? <Loader/> :<form
          onSubmit={handleSubmit(handleAddUserType)}
          className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
        >
          <div>
            <label className="font-medium text-sm">Sport ID</label>
            <select
              {...register("sportId", {
                required: "sport Id is required",
              })}
              disabled={SportLoading}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select</option>
              {Sport &&
                Sport?.sports?.map((value, index) => (
                  <option key={index} value={value?.id}>
                    {value?.sport_name}
                  </option>
                ))}
            </select>
            {errors.sportId && (
              <p className="text-red-500 text-sm">{errors.sportId.message}</p>
            )}
          </div>
          <div>
            <label className="font-medium text-sm">User Type</label>
            <select
              {...register("type", {
                required: "Type is required",
                minLength: 1,
              })}
              disabled={UserTypesLoading}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select type</option>
              {UserTypes &&
                UserTypes?.map((value, index) => (
                  <option key={index} value={value?.id}>
                    {value?.type}
                  </option>
                ))}
            </select>
            {errors.type && (
              <p className="text-red-500 text-sm">{errors.type.message}</p>
            )}
          </div>
          <div>
            <label className="font-medium text-sm">Role</label>
            <input
              type="text"
              placeholder="Enter Role"
              {...register("role", { required: "Role is required" })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.role && (
              <p className="text-red-500 text-sm">{errors.role.message}</p>
            )}
          </div>

          <div>
            <CustomButton
              type="submit"
              disabled={UserPending}
              btntext={
                UserPending ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  "Continue"
                )
              }
              addclass="mt-3"
            />
          </div>
        </form>}
      </div>
    </Sidebar>
  );
};

export default EditUserSport;
