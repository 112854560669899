import { useQueryClient } from '@tanstack/react-query';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSingleCategory } from '../../shopApi/useProduct';
import Sidebar from '../../components/layout/Sidebar';
import Loader from '../../components/Loader';
import { FaAngleLeft } from 'react-icons/fa6';
import Header from '../../components/layout/Header';
import useFormatDate from '../../customHook/useFormatDate';

const CategoryDetail = () => {
    const navigate = useNavigate();
  const { id } = useParams();
  const { SingleCategory, SingleCategoryLoading, SingleCategoryRefetch } =
    useGetSingleCategory(id);
    const formatDate = useFormatDate();

  return (
    <>
    <Sidebar>
    <Header />
    {SingleCategoryLoading ? (
      <Loader />
    ) : (
        <div>

        <div
          className="w-8 h-8 mb-4 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaAngleLeft />
        </div>
        <p className="font-semibold text-sm mb-2">Name: <span className='font-normal text-gray-600'>{SingleCategory.name}</span></p>
        <p className="font-semibold text-sm mb-2">Description: <span className='font-normal text-gray-600'>{SingleCategory.description}</span></p>
        <p className="font-semibold text-sm mb-2">Created_at: <span className='font-normal text-gray-600'>{formatDate(SingleCategory.created_at)}</span></p>
        <p className="font-semibold text-sm mb-2">Updated_at: <span className='font-normal text-gray-600'>{formatDate(SingleCategory.updated_at)}</span></p>

        </div>
    )}
    </Sidebar>
    </>
  )
}

export default CategoryDetail
