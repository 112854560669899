import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { IoMdEye } from "react-icons/io";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import CustomPagination from "../../components/common/CustomPagination";
import Sidebar from "../../components/layout/Sidebar";
import useConfirmToast from "../../customHook/useConfirmToast";
import useFormatDate from "../../customHook/useFormatDate";
import useToast from "../../customHook/useToast";
import {
  useDeleteUsers,
  useDeleteUserType,
  useGetRegisterUser,
  useUserTypes,
} from "../../shopApi/useProduct";
import Header from "../../components/layout/Header";
import CustomButton from "../../components/common/CustomButton";

const UserType = () => {
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(5);
  const [userId, setUserId] = useState();
  // const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  console.log(userId);
  const showToast = useToast();
  const confirmToast = useConfirmToast();
  const { UserTypes, UserTypesLoading, UserTypesRefetch } = useUserTypes();
  const { mutate: DeleteUser } = useDeleteUserType();

  // useEffect(() => {
  //   RegisterUserRefetch();
  // }, [page]);
  // useEffect(() => {
  //   setIsSearch(true);
  //   const timeoutId = setTimeout(() => {
  //     RegisterUserRefetch();
  //     setTimeout(() => {
  //       setIsSearch(false);
  //     }, 1000);
  //   }, 2000);
  //   return () => clearTimeout(timeoutId); // Clean up the timeout
  // }, [searchQuery]);

  const navigate = useNavigate();
  const formatDate = useFormatDate();
  // =========== delete user
  const handleDeleteUser = (id) => {
    // e.preventDefault();
    confirmToast({
      title: "Are you sure?",
      text: "You won't to this delete type!",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      onConfirm: () => {
        DeleteUser(
          {
            userId: id,
          },
          {
            onSuccess: (data) => {
              UserTypesRefetch();
            },
            onError: (error) => {
              showToast({
                status: "error",
                text: error.message,
              });
            },
          }
        );
      },
      onCancel: () => {
        console.log("Action cancelled");
      },
    });
  };
  const handleEditUser = (id) => {
    navigate(`/userType/${id}`);
  };
  // const handleSearch = (query) => {
  //   setSearchQuery(query); // Update search query state
  // };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "TYPE",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "CREATED AT",
      selector: (row) => <div>{formatDate(row.created_at)}</div>,
      sortable: true,
    },
    {
      name: "UPDATED AT",
      selector: (row) => <div>{formatDate(row.updated_at)}</div>,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-1 flex-wrap">
          <div
            onClick={() => handleEditUser(row.id)}
            role="button"
            className="bg-customBlue text-customWhite w-5 h-5 rounded-sm flex justify-center items-center"
          >
            <MdModeEdit />
          </div>
          <div
            onClick={() => handleDeleteUser(row.id)}
            role="button"
            className="bg-red-600 text-customWhite w-5 h-5 rounded-sm flex justify-center items-center"
          >
            <MdDelete />
          </div>
          {/* <div
            onClick={() => navigate(`/user/detail/${row.id}`)} role="button"
            className="bg-blue-600 text-customWhite w-5 h-5 rounded-sm flex justify-center items-center"
          >
            <IoMdEye />
          </div> */}
        </div>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        background: "#e9ecef",
        // borderRadius:"20px"
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <Sidebar>
      <Header />

      {UserTypesLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="mt-2 flex items-center justify-between mb-2">
            <p className="font-semibold text-xl ">Types</p>
            <CustomButton
              btntext="Add Type"
              handleClick={() => navigate("/userType/add")}
            />
          </div>
          <div className="p-3 border rounded-lg">
            <DataTable
              responsive
              columns={columns}
              data={Array.isArray(UserTypes) ? UserTypes : []}
              customStyles={customStyles}
            />
          </div>
          {/* <CustomPagination
            page={page}
            setPage={setPage}
            limit={limit}
            totalPages={RegisterUser?.totalPages}
          /> */}
        </div>
      )}
    </Sidebar>
  );
};

export default UserType;
