import React, { useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useGetMe } from "../../shopApi/useProduct";
const Header = ({ onSearch }) => {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const user = localStorage.getItem("user");
  const { MeData:user, MeLoading, MeRefetch, isSuccess } = useGetMe();

 useEffect(() => {
  MeRefetch()
 }, [])
  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };
  return (
    <>
      <div
        className={`flex gap-3 flex-wrap justify-between items-center pb-3 border-b-[1.5px] border-dashed border-customGray mb-2`}
      >
        {onSearch && (
          <div className="flex gap-2 items-center bg-customLightGray h-12 px-6 rounded-full">
            <input
              type="search"
              placeholder="Search here..."
              className="w-80 border-0 bg-customLightGray focus:outline-none"
              onChange={handleSearchChange}
            />
            <FiSearch />
          </div>
        )}
        
          <div className={`${!onSearch && "ms-auto"} flex gap-2 items-center`}>
            <p className="font-semibold">{user?.fname}</p>
            {user?.profile_image ? (
              <img
                src={user.profile_image.url}
               
                className="rounded-full object-cover h-12 w-12"
                alt="w8"
              />
            ) : (
              <FaUserCircle size={50} />
            )}
            {/* <Menu
            className="mt-1"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
          </Menu> */}
          </div>
      </div>
    
    </>
  );
};

export default Header;
