import React from "react";
import { Route, Routes } from "react-router-dom";
import Forbidden from "../pages/Forbidden";
import Login from "../pages/Login";
import EditUser from "../pages/users/EditUser";
import UserDetail from "../pages/users/UserDetail";
import Users from "../pages/users/Users";
import AddUserSport from "../pages/userSportRole/AddUserSport";
import UserSport from "../pages/userSportRole/UserSport";
import AddUserType from "../pages/userType/AddUserType";
import EditUserType from "../pages/userType/EditUserType";
import UserType from "../pages/userType/UserType";
import ProtectedRoute from "./ProtectedRoute";
import Category from "../pages/category/Category";
import AddCategory from "../pages/category/AddCategory";
import EditCategory from "../pages/category/EditCategory";
import EditUserSport from "../pages/userSportRole/EditUserSport";
import Sports from "../pages/sports/Sports";
import AddSports from "../pages/sports/AddSports";
import EditSports from "../pages/sports/EditSports";
import CategoryDetail from "../pages/category/CategoryDetail";
import CompanyPage from "../pages/companyPage/CompanyPage";
import AddCompany from "../pages/companyPage/AddSports";
import EditCompany from "../pages/companyPage/EditCompany";
import ViewCompany from "../pages/companyPage/ViewCompany";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Forbidden />} />
        <Route exact path="/" element={<ProtectedRoute />}>
          {/* ========== user  */}
          <Route path="/" element={<Users />} />
          <Route path="/user/:id" element={<EditUser />} />
          <Route path="/user/detail/:id" element={<UserDetail />} />
          {/* ========== user types */}
          <Route path="/userType" element={<UserType />} />
          <Route path="/userType/add" element={<AddUserType />} />
          <Route path="/userType/:id" element={<EditUserType />} />
          {/* ========== user sport */}
          <Route path="/userSport" element={<UserSport />} />
          <Route path="/userSport/add" element={<AddUserSport />} />
          <Route path="/userSport/edit/:id" element={<EditUserSport />} />
          {/* ========== Category */}
          <Route path="/category" element={<Category />} />
          <Route path="/category/add" element={<AddCategory />} />
          <Route path="/category/edit/:id" element={<EditCategory />} />
          <Route path="/category/:id" element={<CategoryDetail />} />
          {/* ========== sports */}
          <Route path="/sports" element={<Sports />} />
          <Route path="/sports/add" element={<AddSports />} />
          <Route path="/sports/edit/:id" element={<EditSports />} />
          {/* ========== Company Page */}
          <Route path="/company" element={<CompanyPage />} />
          {/* <Route path="/company/add" element={<AddCompany />} /> */}
          {/* <Route path="/company/edit/:id" element={<EditCompany />} /> */}
          <Route path="/company/detail/:id" element={<ViewCompany />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
