import Axios from "axios";
import { isEmpty } from "lodash";
import queryString from "query-string";

// const API_URL = "https://brilliantweb.semantic-sol.net/api/v1";
const API_URL = "https://benchsocial.it/api/v1";
export const axios = Axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("access-token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (Axios.isAxiosError(error) && error.response?.status === 401) {
      // Clear the token and redirect to the login page
      sessionStorage.removeItem("access-token");

      // Use navigate for redirection if in a React component
      if (window.location.pathname !== "/login") {
          window.location.href = "/login";
      
      }
    }
    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get(url, params) {
    const query = queryString.stringify(params);
    const urlWithQuerryString = isEmpty(query) ? url : `${url}?${query}`;
    const response = await axios.get(urlWithQuerryString);
    return response.data;
  }

  static async post(url, data, options) {
    const response = await axios.post(url, data, options);
    return response.data;
  }

  static async put(url, data, options) {
    const response = await axios.put(url, data, options);
    return response.data;
  }

  static async patch(url, data) {
    const response = await axios.patch(url, data);
    return response.data;
  }

  static async delete(url) {
    const response = await axios.delete(url);
    return response.data;
  }

  static formatSearchParams(params) {
    return Object.entries(params)
      .filter(([, value]) => value)
      .map(([k, v]) =>
        ["type", "categories", "tags", "author", "manufacturer"].includes(k)
          ? `${k}.slug:${v}`
          : ["is_approved"].includes(k)
          ? formatBooleanSearchParam(k, v)
          : `${k}:${v}`
      )
      .join(";");
  }
}

function formatBooleanSearchParam(key, value) {
  return value ? `${key}:1` : `${key}:`;
}

export function getFormErrors(error) {
  if (Axios.isAxiosError(error)) {
    return error.response?.data.message;
  }
  return null;
}

export function getFieldErrors(error) {
  if (Axios.isAxiosError(error)) {
    return error.response?.data.errors;
  }
  return null;
}
