import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa6";
import { json, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import Loader from "../../components/Loader";
import useToast from "../../customHook/useToast";
import {
  useGetSingleUser,
  useGetSport,
  useUpdateUser,
  useUserSport,
  useUserTypes,
} from "../../shopApi/useProduct";
// import Autocomplete from "react-google-autocomplete";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";

import { useForm } from "react-hook-form";

const EditUser = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  const { id } = useParams();
  const { SingleUser, SingleUserLoading, SingleUserRefetch } =
    useGetSingleUser(id);
  const { mutate: EditRegisterUser, isPending: EditUserPending } =
    useUpdateUser(id);
  const { UserTypes, UserTypesRefetch } = useUserTypes();
  const { Sport, SportRefetch } = useGetSport({
    page: 1,
    limit: 100,
  });
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const User_Type = watch("userTypeId");
  const Sport_Id = watch("sportId");
  const { UserSport, UserSportLoading, UserSportRefetch } = useUserSport({
    userTypeId: User_Type,
    sportId: Sport_Id,
  });
  useEffect(() => {
    if (User_Type && Sport_Id) {
      UserSportRefetch();
    }
    console.log(UserSport);
  }, [Sport_Id, User_Type]);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState("");

  const [location, setLocation] = useState({
    details: "",
    city: "",
    latitude: 0,
    longitude: 0,
    state: "",
    country: "",
  });
  useEffect(() => {
    console.log("🚀 ~ EditUser ~ location:", location);
    SingleUserRefetch();
    UserTypesRefetch();
    UserSportRefetch();
    SportRefetch();
  }, [id]);

  useEffect(() => {
    if (SingleUser && !SingleUserLoading && SingleUser?.id) {
      setValue("fname", SingleUser.fname);
      setValue("lname", SingleUser.lname);
      setValue("sportId", SingleUser.sport?.id);
      setValue("userTypeId", SingleUser.userType?.id);
      setValue("sportRoleId", SingleUser.sportRole?.id);
      setValue("role", SingleUser.role);
      setValue("gender", SingleUser.gender);
      setValue("bio", SingleUser.bio);
      setValue("height", SingleUser.height_in_cm);
      setValue("weight", SingleUser.weight_in_kg);
      setValue("footNumber", SingleUser.foot_number);
      setValue("preferred_hand_or_foot", SingleUser.preferred_hand_or_foot);
      setProfileImageUrl(SingleUser.profile_image?.url || "");
      setLocation({
        details: SingleUser?.location?.details || "",
        city: SingleUser?.location?.city || "",
        latitude: SingleUser?.location?.latitude || 0,
        longitude: SingleUser?.location?.longitude || 0,
        state: SingleUser?.location?.state || "",
        country: SingleUser?.location?.country || "",
      });
    }
  }, [SingleUser]);

  useEffect(() => {
    if (SingleUser && !SingleUserLoading && SingleUser?.id) {
      setValue("sportId", SingleUser.sport?.id);
    }
  }, [SingleUser, Sport]);
  useEffect(() => {
    if (SingleUser && !SingleUserLoading && SingleUser?.id) {
      setValue("userTypeId", SingleUser.userType?.id);
    }
  }, [SingleUser, UserTypes]);

  useEffect(() => {
    if (SingleUser && !SingleUserLoading && SingleUser?.id) {
      setValue("sportRoleId", SingleUser.sportRole?.id);
    }
  }, [SingleUser, UserSport]);

  const handleProfileChange = (e) => {
    const file = e.target.files[0]; // Get the first file from the array
    setProfileImage(file);
    setProfileImageUrl(URL.createObjectURL(file));
  };

  // const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
  });
  const autocompleteRef = useRef(null);
  const handlePlaceSelected = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place || !place.address_components) return;

    const addressComponents = place.address_components;
    let street = "";
    let city = "";
    let state = "";
    let postalCode = "";
    let country = "";

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        street = component.long_name + " " + street;
      } else if (types.includes("route")) {
        street += component.long_name;
      } else if (types.includes("locality")) {
        city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (types.includes("postal_code")) {
        postalCode = component.long_name;
      } else if (types.includes("country")) {
        country = component.long_name;
      } else if (
        types.includes("sublocality") ||
        types.includes("neighborhood")
      ) {
        street += component.long_name + " ";
      }
    });

    if (!street) {
      street = place.formatted_address.split(",")[0] || "";
    }
    if (!city) {
      city = place.formatted_address.split(",")[1]?.trim() || "";
    }
    if (!state) {
      state = place.formatted_address.split(",")[2]?.split(" ")[0] || "";
    }
    if (!country) {
      country = place.formatted_address.split(",").pop()?.trim() || "";
    }
    setLocation({
      details: place.formatted_address || "",
      city,
      latitude: place.geometry.location.lat() || 0,
      longitude: place.geometry.location.lng() || 0,
      state,
      country,
    });
  };
  // edit user
  const handleEdit = async (data) => {
    const formData = new FormData();
    if (data.sportId) formData.append("sportId", data.sportId);
    if (data.userTypeId) formData.append("userTypeId", data.userTypeId);
    if (data.sportRoleId) formData.append("sportRoleId", data.sportRoleId);
    if (data.fname) formData.append("fname", data.fname);
    if (data.lname) formData.append("lname", data.lname);
    if (data.bio) formData.append("bio", data.bio);
    if (data.role) formData.append("role", data.role);
    if (data.gender) formData.append("gender", data.gender);
    if (data.height) formData.append("height_in_cm", data.height);
    if (data.weight) formData.append("weight_in_kg", data.weight);
    if (data.footNumber) formData.append("foot_number", data.footNumber);
    if (data.preferred_hand_or_foot)
      formData.append("preferred_hand_or_foot", data.preferred_hand_or_foot);
    formData.append("location", JSON.stringify(location));
    if (profileImage) formData.append("profile_image", profileImage);
    console.log(profileImage, "profileImage");
    console.log(formData);
    EditRegisterUser(formData, {
      onSuccess: (data) => {
        showToast({ status: "success", text: "successfully update" });
        navigate("/");
        query.clear();
      },
      onError: (error) => {
        showToast({ status: "error", text: error.response.data.message });
      },
    });
  };
  return (
    <Sidebar>
      <Header />

      {SingleUserLoading ? (
        <Loader />
      ) : (
        <div>
          <div
            className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
            onClick={() => {
              navigate(-1);
              query.clear();
            }}
          >
            <FaAngleLeft />
          </div>
          <form
            onSubmit={handleSubmit(handleEdit)}
            className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
          >
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-3">
              <div>
                <label className="font-medium text-sm">First Name</label>
                <input
                  type="text"
                  {...register("fname")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                />
              </div>
              <div>
                <label className="font-medium text-sm">Last Name</label>
                <input
                  type="text"
                  {...register("lname")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                />
              </div>
              <div>
                <label className="font-medium text-sm">Sport ID</label>
                <select
                  {...register("sportId")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                >
                  <option value="">Select</option>
                  {Sport &&
                    Sport?.sports?.map((value, index) => (
                      <option key={index} value={value?.id}>
                        {value?.sport_name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label className="font-medium text-sm">User Type Id</label>

                <select
                  {...register("userTypeId")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                >
                  <option value="">Select</option>
                  {UserTypes &&
                    UserTypes?.map((value, index) => (
                      <option key={index} value={value?.id}>
                        {value?.type}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label className="font-medium text-sm">Sport Role</label>

                <select
                  {...register("sportRoleId")}
                  disabled={UserSportLoading || !Sport_Id || !User_Type}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                >
                  <option value="" selected>
                    Select
                  </option>
                  {UserSport && UserSport?.userSportRoles?.length > 0 ? (
                    UserSport?.userSportRoles?.map((value, index) => (
                      <option key={index} value={value?.id}>
                        {value?.role}
                      </option>
                    ))
                  ) : (
                    <option disabled value="">
                      No roles available
                    </option>
                  )}
                </select>
              </div>
              <div>
                <label className="font-medium text-sm">Role</label>

                <select
                  {...register("role")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                >
                  <option value="">Select</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </select>
              </div>
              <div>
                <label className="font-medium text-sm">Gender</label>

                <select
                  {...register("gender")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                >
                  <option value="">Select</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </div>

              <div>
                <label className="font-medium text-sm">Height in cm</label>
                <input
                  type="text"
                  {...register("height", {
                    required: false,
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Height must be a number",
                    },
                  })}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                />
                {errors.height && (
                  <p className="text-red-500 text-sm">
                    {errors.height.message}
                  </p>
                )}
              </div>
              <div>
                <label className="font-medium text-sm">weight in kg</label>
                <input
                  type="text"
                  {...register("weight", {
                    required: false,
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Weight must be a number",
                    },
                  })}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                />
                {errors.weight && (
                  <p className="text-red-500 text-sm">
                    {errors.weight.message}
                  </p>
                )}
              </div>
              <div>
                <label className="font-medium text-sm">Foot Number</label>
                <input
                  type="text"
                  {...register("footNumber", {
                    required: false,
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Foot Number must be a number",
                    },
                  })}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                />
                {errors.footNumber && (
                  <p className="text-red-500 text-sm">
                    {errors.footNumber.message}
                  </p>
                )}
              </div>
              <div>
                <label className="font-medium text-sm">
                  preferred_hand_or_foot
                </label>
                {/* <input
                  type="text"
                  {...register("preferred_hand_or_foot")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                /> */}
                <select
                  {...register("preferred_hand_or_foot")}
                  className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
                >
                  <option value="">Select</option>
                  <option value="Right">Right</option>
                  <option value="Left">Left</option>
                  <option value="Ambidextrous">Ambidextrous</option>
                </select>
              </div>

              <div className="">
                <p className="font-medium text-sm">Location</p>

                {isLoaded && (
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (autocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={handlePlaceSelected}
                  >
                    <div className="">
                      <input
                        type="text"
                        defaultValue={location.details || ""}
                        id="Location"
                        placeholder="Search for a location"
                        className="bg-customWhite text-sm mt-1 p-2.5 rounded-full border-0 focus:outline-none w-full"
                        required
                      />
                    </div>
                  </Autocomplete>
                )}
              </div>
            </div>
            <div>
              <label className="font-medium text-sm">Bio</label>
              <textarea
                rows={5}
                {...register("bio")}
                className="bg-customWhite text-sm p-2.5 rounded-xl border-0 focus:outline-none w-full"
              ></textarea>
            </div>

            <div className="mt-2 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
              <div>
                <p className="font-medium text-sm">Profile Image</p>
                <label
                  htmlFor="file"
                  className="font-medium text-sm cursor-pointer"
                >
                  <div className="bg-customWhite flex justify-between h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full">
                    <p>Chose file</p>
                    <FaUpload />
                  </div>
                </label>
                <input
                  type="file"
                  onChange={handleProfileChange}
                  id="file"
                  className="hidden"
                  accept=".jpg, .jpeg, .png"
                />
                {profileImageUrl && (
                  <div className="mt-2">
                    <img
                      src={profileImageUrl}
                      alt={`Selected file ${profileImageUrl?.name}`}
                      className=" rounded-lg w-28 h-28"
                    />
                  </div>
                )}
                {/* {!profileImage && SingleUser?.profile_image?.url && (
                  <div className="mt-2">
                    <img
                      src={SingleUser?.profile_image?.url}
                      alt={`Selected file ${SingleUser?.profile_image.name}`}
                      className=" rounded-lg w-28 h-28"
                    />
                  </div>
                )} */}
              </div>
            </div>
            <div>
              <CustomButton
                type="submit"
                disabled={EditUserPending}
                handleClick={handleEdit}
                btntext={
                  EditUserPending ? (
                    <CircularProgress sx={{ color: "white" }} size={20} />
                  ) : (
                    "Continue"
                  )
                }
                addclass="mt-3"
              />
            </div>
          </form>
        </div>
      )}
    </Sidebar>
  );
};

export default EditUser;
