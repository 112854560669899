import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import useToast from "../../customHook/useToast";
import {
  useEditUserType,
  useGetSingleSport,
  useGetSingleUserType,
  useUpdateSport,
} from "../../shopApi/useProduct";
import Loader from "../../components/Loader";

const EditSports = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  const { id } = useParams();
  const { SingleSport, SingleSportLoading, SingleSportRefetch } =
    useGetSingleSport(id);
  const { mutate: EditSport, isPending: UserPending } = useUpdateSport();
  const showToast = useToast();
  useEffect(() => {
    SingleSportRefetch();
  }, [id]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (SingleSport && SingleSport?.id) {
      setValue("sport_name", SingleSport.sport_name);
    }
  }, [SingleSport]);
  // edit user
  const handleAddSport = async (data) => {
    EditSport(
      {
        sport_name: data.sport_name,
        id: id,
      },
      {
        onSuccess: (data) => {
          showToast({ status: "success", text: "successfully update" });
          navigate("/sports");
          query.clear();
        },
        onError: (error) => {
          // if (
          //   error.response.data.message === `The Sport with name ${data.sport_name} is already exists!`
          // ) {
          //   showToast({ status: "success", text: "successfully add" });
          //   navigate("/sports");
          //   query.clear();
          // } else {
            showToast({ status: "error", text: error.response.data.message });
          // }
        },
      }
    );
  };

  return (
    <Sidebar>
      <Header />
      {SingleSportLoading ? (
        <Loader />
      ) : (
        <div>
          <div
            className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
            onClick={() => {
              navigate(-1);
              query.clear();
            }}
          >
            <FaAngleLeft />
          </div>
          <form
            onSubmit={handleSubmit(handleAddSport)}
            className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
          >
            <div>
              <label className="font-medium text-sm">Sport Name</label>
              <input
                type="text"
                {...register("sport_name", {
                  required: "Sport Name is required",
                })}
                className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
              />

              {errors.sport_name && (
                <p className="text-red-500 text-sm">
                  {errors.sport_name.message}
                </p>
              )}
            </div>

            <div>
              <CustomButton
                type="submit"
                disabled={UserPending}
                btntext={
                  UserPending ? (
                    <CircularProgress sx={{ color: "white" }} size={20} />
                  ) : (
                    "Continue"
                  )
                }
                addclass="mt-3"
              />
            </div>
          </form>
        </div>
      )}
    </Sidebar>
  );
};

export default EditSports;
