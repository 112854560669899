import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

const useToast = () => {
  const showToast = ({ status, text }) => {
    Toast.fire({
      icon: status,
      title: text
    });
  };

  return showToast;
};

export default useToast;
