import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import CustomButton from "../../components/common/CustomButton";
import CustomPagination from "../../components/common/CustomPagination";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import useConfirmToast from "../../customHook/useConfirmToast";
import useFormatDate from "../../customHook/useFormatDate";
import useToast from "../../customHook/useToast";
import {
  useDeleteCompany,
  useGetCompany
} from "../../shopApi/useProduct";
import { IoMdEye } from "react-icons/io";

const CompanyPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [userId, setUserId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  console.log(userId);
  const showToast = useToast();
  const confirmToast = useConfirmToast();
  const {Company, CompanyLoading, CompanyRefetch} =
  useGetCompany({
      page,
      limit,
      keyword: searchQuery,
    });
  const { mutate: DeleteCompany } = useDeleteCompany();

  useEffect(() => {
    CompanyRefetch();
  }, [page]);
  useEffect(() => {
    setIsSearch(true);
    const timeoutId = setTimeout(() => {
      CompanyRefetch();
      setTimeout(() => {
        setIsSearch(false);
      }, 1000);
    }, 2000);
    return () => clearTimeout(timeoutId); // Clean up the timeout
  }, [searchQuery]);

  const navigate = useNavigate();
  const formatDate = useFormatDate();
  // =========== delete Company
  const handleDeleteUser = (id) => {
    // e.preventDefault();
    confirmToast({
      title: "Are you sure?",
      text: "You won't to this delete Company!",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      onConfirm: () => {
        DeleteCompany(
          {
            id: id,
          },
          {
            onSuccess: (data) => {
              CompanyRefetch();
            },
            onError: (error) => {
              showToast({
                status: "error",
                text: error.message,
              });
            },
          }
        );
      },
      onCancel: () => {
        console.log("Action cancelled");
      },
    });
  };
  const handleEditUser = (id) => {
    navigate(`/company/edit/${id}`);
  };
  const handleSearch = (query) => {
    setSearchQuery(query); // Update search query state
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "club name",
      selector: (row) => row.club_name,
      sortable: true,
    },  
    // {
    //   name: "website",
    //   selector: (row) => row.website,
    //   sortable: true,
    // },  
    // {
    //   name: "no of teams",
    //   selector: (row) => row.no_of_teams,
    //   sortable: true,
    // },  
    // {
    //   name: "to day",
    //   selector: (row) => row.no_of_teams,
    //   sortable: true,
    // },  
    // {
    //   name: "from time",
    //   selector: (row) => row.from_time,
    //   sortable: true,
    // },
    // {
    //   name: "to_time",
    //   selector: (row) => row.to_time,
    //   sortable: true,
    // },
    {
      name: "CREATED AT",
      selector: (row) => <div>{formatDate(row.created_at)}</div>,
      sortable: true,
    },
    // {
    //   name: "UPDATED AT",
    //   selector: (row) => <div>{formatDate(row.updated_at)}</div>,
    //   sortable: true,
    // },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-1 flex-wrap">
          {/* <div
            onClick={() => handleEditUser(row.id)} role="button"
            className="bg-customBlue text-customWhite w-5 h-5 rounded-sm flex justify-center items-center"
          >
            <MdModeEdit />
          </div> */}
          <div
            onClick={() => handleDeleteUser(row.id)} role="button"
            className="bg-red-600 text-customWhite w-5 h-5 rounded-sm flex justify-center items-center"
          >
            <MdDelete />
          </div>
          <div
            onClick={() => navigate(`/company/detail/${row.id}`)} role="button"
            className="bg-blue-600 text-customWhite w-5 h-5 rounded-sm flex justify-center items-center"
          >
            <IoMdEye />
          </div>
        </div>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        background: "#e9ecef",
        textTransform:"uppercase"
        // borderRadius:"20px"
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <Sidebar>
      <Header onSearch={handleSearch}/>

      {CompanyLoading || isSearch ? (
        <Loader />
      ) : (
        <div>
          <div className="my-2 ">
              <p className="font-semibold text-xl ">Company</p>
              {/* <CustomButton
                btntext="Add Company"
                handleClick={() => navigate("/company/add")}
              /> */}
            </div>
          <div className="p-3 border rounded-lg">
            <DataTable
              responsive
              columns={columns}
              data={Company?.pages || []}
              customStyles={customStyles}
            />
          </div>
          <CustomPagination
            page={page}
            setPage={setPage}
            limit={limit}
            totalPages={Company?.totalPages}
          />
        </div>
      )}
    </Sidebar>
  );
};

export default CompanyPage;
