import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import Loader from "../../components/Loader";
import useToast from "../../customHook/useToast";
import { useAddUserType } from "../../shopApi/useProduct";

const AddUserType = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  // const { id } = useParams();
  // const { SingleUser, SingleUserLoading, SingleUserRefetch } =
  //   useGetSingleUser(id);
  const { mutate: AddUser, isPending: UserPending } = useAddUserType();
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // edit user
  const handleAddUserType = async (data) => {
    AddUser(
      {
        type: data.type,
      },
      {
        onSuccess: (data) => {
          showToast({ status: "success", text: "successfully add" });
          navigate("/userType");
          query.clear();
        },
        onError: (error) => {
          // if (
          //   error.response.data.message ===
          //   `The type: ${data.type} is already exists!`
          // ) {
          //   showToast({ status: "success", text: "successfully add" });
          //   navigate("/userType");
          //   query.clear();
          // } else {
            showToast({ status: "error", text: error.response.data.message });
          // }
        },
      }
    );
  };

  return (
    <Sidebar>
      <Header />

      <div>
        <div
          className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
          onClick={() => {
            navigate(-1);
            query.clear();
          }}
        >
          <FaAngleLeft />
        </div>
        <form
          onSubmit={handleSubmit(handleAddUserType)}
          className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
        >
          <div>
            <label className="font-medium text-sm">Type</label>
            <input
              type="text"
              {...register("type", { required: "Type is required" })}
              placeholder="name"
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />
            {errors.type && (
              <p className="text-red-500 text-sm">{errors.type.message}</p>
            )}
          </div>

          <div>
            <CustomButton
              type="submit"
              disabled={UserPending}
              btntext={
                UserPending ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  "Continue"
                )
              }
              addclass="mt-3"
            />
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddUserType;
