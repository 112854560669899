import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { products } from "./client/product";

//AUTH Apis

export function useLogin() {
  return useMutation({
    mutationFn: (body) => products.login(body),
  });
}
// ===== users

export function useGetRegisterUser(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useRegisterUser"],
    queryFn: () => products.GetRegisterUser(option),
  });

  return {
    RegisterUser: data,
    RegisterUserLoading: isLoading,
    error,
    status,
    isSuccess,
    RegisterUserRefetch: refetch,
  };
}
export function useGetMe(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useGetMe"],
    queryFn: () => products.GetMe(option),
  });

  return {
    MeData: data,
    MeLoading: isLoading,
    error,
    status,
    isSuccess,
    MeRefetch: refetch,
  };
}
export function useDeleteUsers() {
  return useMutation({
    mutationFn: (body) => products.DeleteUsers(body),
  });
}
export function useGetSingleUser(id) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSingleUser"],
    queryFn: () => products.GetUserById(id),
  });

  return {
    SingleUser: data,
    SingleUserLoading: isLoading,
    error,
    status,
    isSuccess,
    SingleUserRefetch: refetch,
  };
}
export function useUpdateUser(id) {
  return useMutation({
    mutationFn: (body) => products.UpdateUser(body, id),
  });
}
// ===== users types
export function useAddUserType() {
  return useMutation({
    mutationFn: (body) => products.AddUserType(body),
  });
}
export function useUserTypes(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useUsertypes"],
    queryFn: () => products.GetUserType(option),
  });

  return {
    UserTypes: data,
    UserTypesLoading: isLoading,
    error,
    status,
    isSuccess,
    UserTypesRefetch: refetch,
  };
}
export function useGetSingleUserType(id) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSingleUserType"],
    queryFn: () => products.GetUserTypeById(id),
  });

  return {
    SingleUserType: data,
    SingleUserTypeLoading: isLoading,
    error,
    status,
    isSuccess,
    SingleUserTypeRefetch: refetch,
  };
}
export function useEditUserType() {
  return useMutation({
    mutationFn: (body) => products.EditUserType(body.id, body),
  });
}
export function useDeleteUserType() {
  return useMutation({
    mutationFn: (body) => products.DeleteUserType(body),
  });
}
// ===== users sport role
export function useAddUserSport() {
  return useMutation({
    mutationFn: (body) => products.AddUserSport(body),
  });
}
export function useUserSport(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useUserSport"],
    queryFn: () => products.GetUserSport(option),
  });

  return {
    UserSport: data,
    UserSportLoading: isLoading,
    error,
    status,
    isSuccess,
    UserSportRefetch: refetch,
  };
}
export function useGetSingleUserSport(id) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSingleUserSport"],
    queryFn: () => products.GetUserSportById(id),
  });

  return {
    SingleUserSport: data,
    SingleUserSportLoading: isLoading,
    error,
    status,
    isSuccess,
    SingleUserSportRefetch: refetch,
  };
}
export function useEditUserSport() {
  return useMutation({
    mutationFn: (body) => products.EditUserSport(body.id, body),
  });
}
export function useDeleteUserSport() {
  return useMutation({
    mutationFn: (body) => products.DeleteUserSport(body),
  });
}
// ===== job category

export function useGetCategory(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useCategory"],
    queryFn: () => products.GetCategory(option),
  });

  return {
    Category: data,
    CategoryLoading: isLoading,
    error,
    status,
    isSuccess,
    CategoryRefetch: refetch,
  };
}
export function useAddCategory() {
  return useMutation({
    mutationFn: (body) => products.AddCategory(body),
  });
}
export function useGetSingleCategory(id) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSingleCategory"],
    queryFn: () => products.GetCategoryById(id),
  });

  return {
    SingleCategory: data,
    SingleCategoryLoading: isLoading,
    error,
    status,
    isSuccess,
    SingleCategoryRefetch: refetch,
  };
}
export function useUpdateCategory() {
  return useMutation({
    mutationFn: (body) => products.UpdateCategory(body.id, body),
  });
}
export function useDeleteCategory() {
  return useMutation({
    mutationFn: (body) => products.DeleteCategory(body.id),
  });
}
// ===== Sport

export function useGetSport(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSport"],
    queryFn: () => products.GetSport(option),
  });

  return {
    Sport: data,
    SportLoading: isLoading,
    error,
    status,
    isSuccess,
    SportRefetch: refetch,
  };
}
export function useAddSport() {
  return useMutation({
    mutationFn: (body) => products.AddSport(body),
  });
}
export function useGetSingleSport(id) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSingleSport"],
    queryFn: () => products.GetSportById(id),
  });

  return {
    SingleSport: data,
    SingleSportLoading: isLoading,
    error,
    status,
    isSuccess,
    SingleSportRefetch: refetch,
  };
}
export function useUpdateSport() {
  return useMutation({
    mutationFn: (body) => products.UpdateSport(body.id, body),
  });
}
export function useDeleteSport() {
  return useMutation({
    mutationFn: (body) => products.DeleteSport(body.id),
  });
}
// ===== Company

export function useGetCompany(option) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useCompany"],
    queryFn: () => products.GetCompany(option),
  });

  return {
    Company: data,
    CompanyLoading: isLoading,
    error,
    status,
    isSuccess,
    CompanyRefetch: refetch,
  };
}
export function useAddCompany() {
  return useMutation({
    mutationFn: (body) => products.AddCompany(body),
  });
}
export function useGetSingleCompany(id) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useSingleCompany"],
    queryFn: () => products.GetCompanyById(id),
  });

  return {
    SingleCompany: data,
    SingleCompanyLoading: isLoading,
    error,
    status,
    isSuccess,
    SingleCompanyRefetch: refetch,
  };
}
export function useUpdateCompany(id) {
  return useMutation({
    mutationFn: (body) => products.UpdateCompany(id, body),
  });
}
export function useDeleteCompany() {
  return useMutation({
    mutationFn: (body) => products.DeleteCompany(body.id),
  });
}


