import UserType from "../../pages/userType/UserType";

export const API_ENDPOINTS = {
  LOGIN: "/auth/login",
  // USERS
  REGISTERUSER: "/users",
  // USERS TYPE
  USERTYPE: "/user-types",
  // USERS SPORT ROLE
  USERSPORTROLE: "/user-sport-role",
  //  CATEGORY
  CATEGORY: "/categories",
  //  SPORT
  SPORT: "/sports",
  //  SPORT
  COMPANY: "/user-pages",
};
