import { API_ENDPOINTS } from "./api-endpoints";
import { HttpClient } from "./http-client";

export const products = {
  //AUTH Apis
  login: (body) => {
    return HttpClient.post(API_ENDPOINTS.LOGIN, body);
  },

  // Users api
  GetMe: async (option) => {
    const response = await HttpClient.get(
      `${API_ENDPOINTS.REGISTERUSER}/me`,
      option
    );
    return response;
  },
  GetRegisterUser: async (option) => {
    const response = await HttpClient.get(API_ENDPOINTS.REGISTERUSER, option);
    return response;
  },
  DeleteUsers: async (body) => {
    const response = await HttpClient.delete(
      `${API_ENDPOINTS.REGISTERUSER}/${body.userId}`
    );
    return response;
  },
  GetUserById: async (id) => {
    const response = await HttpClient.get(
      `${API_ENDPOINTS.REGISTERUSER}/${id}`
    );
    return response;
  },
  UpdateUser: async (body, id) => {
    // delete body.id;
    const Header = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    const response = await HttpClient.put(
      `${API_ENDPOINTS.REGISTERUSER}/${id}`,
      body,
      { headers: Header }
    );
    return response;
  },

  // User types
  AddUserType: async (body) => {
    const response = await HttpClient.post(API_ENDPOINTS.USERTYPE, body);
    return response;
  },
  GetUserType: async (option) => {
    const response = await HttpClient.get(API_ENDPOINTS.USERTYPE, option);
    return response;
  },
  GetUserTypeById: async (id) => {
    const response = await HttpClient.get(`${API_ENDPOINTS.USERTYPE}/${id}`);
    return response;
  },
  EditUserType: async (id, body) => {
    delete body.id;
    const response = await HttpClient.put(
      `${API_ENDPOINTS.USERTYPE}/${id}`,
      body
    );
    return response;
  },
  DeleteUserType: async (body) => {
    const response = await HttpClient.delete(
      `${API_ENDPOINTS.USERTYPE}/${body.userId}`
    );
    return response;
  },

  // User SPORT ROLE
  AddUserSport: async (body) => {
    const response = await HttpClient.post(API_ENDPOINTS.USERSPORTROLE, body);
    return response;
  },
  GetUserSport: async (option) => {
    const response = await HttpClient.get(API_ENDPOINTS.USERSPORTROLE, option);
    return response;
  },
  GetUserSportById: async (id) => {
    const response = await HttpClient.get(
      `${API_ENDPOINTS.USERSPORTROLE}/${id}`
    );
    return response;
  },
  EditUserSport: async (id, body) => {
    delete body.id;
    const response = await HttpClient.put(
      `${API_ENDPOINTS.USERSPORTROLE}/${id}`,
      body
    );
    return response;
  },
  DeleteUserSport: async (body) => {
    const response = await HttpClient.delete(
      `${API_ENDPOINTS.USERSPORTROLE}/${body.userId}`
    );
    return response;
  },

  // category api

  GetCategory: async (option) => {
    const response = await HttpClient.get(API_ENDPOINTS.CATEGORY, option);
    return response;
  },
  AddCategory: async (body) => {
    const response = await HttpClient.post(API_ENDPOINTS.CATEGORY, body);
    return response;
  },
  GetCategoryById: async (id) => {
    const response = await HttpClient.get(`${API_ENDPOINTS.CATEGORY}/${id}`);
    return response;
  },
  UpdateCategory: async (id, body) => {
    delete body.id;
    const response = await HttpClient.put(
      `${API_ENDPOINTS.CATEGORY}/${id}`,
      body
    );
    return response;
  },
  DeleteCategory: async (id) => {
    const response = await HttpClient.delete(`${API_ENDPOINTS.CATEGORY}/${id}`);
    return response;
  },

  // sport api

  GetSport: async (option) => {
    const response = await HttpClient.get(API_ENDPOINTS.SPORT, option);
    return response;
  },
  AddSport: async (body) => {
    const response = await HttpClient.post(API_ENDPOINTS.SPORT, body);
    return response;
  },
  GetSportById: async (id) => {
    const response = await HttpClient.get(`${API_ENDPOINTS.SPORT}/${id}`);
    return response;
  },
  UpdateSport: async (id, body) => {
    delete body.id;
    const response = await HttpClient.put(`${API_ENDPOINTS.SPORT}/${id}`, body);
    return response;
  },
  DeleteSport: async (id) => {
    const response = await HttpClient.delete(`${API_ENDPOINTS.SPORT}/${id}`);
    return response;
  },

  // company pages

  GetCompany: async (option) => {
    const response = await HttpClient.get(API_ENDPOINTS.COMPANY, option);
    return response;
  },
  AddCompany: async (body) => {
    const response = await HttpClient.post(API_ENDPOINTS.COMPANY, body);
    return response;
  },
  GetCompanyById: async (id) => {
    const response = await HttpClient.get(`${API_ENDPOINTS.COMPANY}/${id}`);
    return response;
  },
  UpdateCompany: async (id, body) => {
    delete body.id;
    const Header = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    const response = await HttpClient.put(
      `${API_ENDPOINTS.COMPANY}/${id}`,
      body,
      { headers: Header }
    );
    return response;
  },
  DeleteCompany: async (id) => {
    const response = await HttpClient.delete(`${API_ENDPOINTS.COMPANY}/${id}`);
    return response;
  },
};
