import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import useToast from "../../customHook/useToast";
import {
  useAddCompany,
  useGetSport,
} from "../../shopApi/useProduct";
import moment from "moment";

const AddCompany = () => {
  const navigate = useNavigate();
  const query = useQueryClient();
  const { mutate: AddCompany, isPending: UserPending } = useAddCompany();
  const { Sport, SportRefetch } = useGetSport({
    page: 1,
    limit: 100,
  });
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  console.log(watch("from_time"));
  console.log(watch("to_time"));
  useEffect(() => {
    SportRefetch();
  }, []);

  const handleAddSport = async (data) => {
    let fromtime =  moment(data.from_time, 'HH:mm').format('hh:mm:ss A');
    let totime =  moment(data.to_time, 'HH:mm').format('hh:mm:ss A');
    console.log(fromtime)
    AddCompany(
      {
        club_name: data.club_name,
        website: data.website,
        no_of_teams: data.no_of_teams,
        from_day: data.from_day,
        to_day: data.to_day,
        from_time: fromtime,
        to_time: totime,
        sportId: data.sportId,
      },
      {
        onSuccess: (data) => {
          showToast({ status: "success", text: "successfully add" });
          navigate("/company");
          query.clear();
        },
        onError: (error) => {
          showToast({ status: "error", text: error.response.data.message });
        },
      }
    );
  };

  return (
    <Sidebar>
      <Header />

      <div>
        <div
          className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
          onClick={() => {
            navigate(-1);
            query.clear();
          }}
        >
          <FaAngleLeft />
        </div>
        <form
          onSubmit={handleSubmit(handleAddSport)}
          className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg"
        >
          <div  className="mt-2">
            <label className="font-medium text-sm">Club Name</label>
            <input
              type="text"
              {...register("club_name", {
                required: "club name is required",
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.club_name && (
              <p className="text-red-500 text-sm">{errors.club_name.message}</p>
            )}
          </div>
          <div  className="mt-2">
            <label className="font-medium text-sm">Website</label>
            <input
              type="text"
              {...register("website", {
                required: "website is required",
                pattern: {
                  value:
                    /^(https?:\/\/)?([\w\d\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                  message: "Please enter a valid URL",
                },
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.website && (
              <p className="text-red-500 text-sm">{errors.website.message}</p>
            )}
          </div>
          <div  className="mt-2">
            <label className="font-medium text-sm">From day</label>
            <select
              {...register("from_day", { required: "From day is required" })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select day</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
            {errors.from_day && (
              <p className="text-red-500 text-sm">{errors.from_day.message}</p>
            )}
          </div>
          <div  className="mt-2">
            <label className="font-medium text-sm">To day</label>
            <select
              {...register("to_day", { required: "To day is required" })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select day</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
            {errors.to_day && (
              <p className="text-red-500 text-sm">{errors.to_day.message}</p>
            )}
          </div>
          <div  className="mt-2">
            <label className="font-medium text-sm">No of teams</label>
            <input
              type="text"
              {...register("no_of_teams", {
                required: "No of teams is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a valid number",
                },
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.no_of_teams && (
              <p className="text-red-500 text-sm">
                {errors.no_of_teams.message}
              </p>
            )}
          </div>
          <div  className="mt-2">
            <label className="font-medium text-sm">From time</label>
            <input
              type="time"
              {...register("from_time", {
                required: "from_time is required",
                // pattern: {
                //   value: /^(0[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9]) [AP]M$/,
                //   message: "From time must be in the format HH:mm:ss AM/PM",
                // },
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.from_time && (
              <p className="text-red-500 text-sm">{errors.from_time.message}</p>
            )}
          </div>

          <div  className="mt-2">
            <label className="font-medium text-sm">To time</label>
            <input
              type="time"
              {...register("to_time", {
                required: "to_time is required",
                // pattern: {
                //   value: /^(0[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9]) [AP]M$/,
                //   message: "To time must be in the format HH:mm:ss AM/PM",
                // },
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            />

            {errors.to_time && (
              <p className="text-red-500 text-sm">{errors.to_time.message}</p>
            )}
          </div>
          <div  className="mt-2">
            <label className="font-medium text-sm">Sport</label>
            <select
              {...register("sportId",{
                required: "sport is required",
              })}
              className="bg-customWhite h-10 text-sm p-2.5 rounded-full border-0 focus:outline-none w-full"
            >
              <option value="">Select</option>
              {Sport &&
                Sport?.sports?.map((value, index) => (
                  <option key={index} value={value?.id}>
                    {value?.sport_name}
                  </option>
                ))}
            </select>
            {errors.sportId && (
              <p className="text-red-500 text-sm">{errors.sportId.message}</p>
            )}
          </div>
          <div>
            <CustomButton
              type="submit"
              disabled={UserPending}
              btntext={
                UserPending ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  "Continue"
                )
              }
              addclass="mt-3"
            />
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddCompany;
