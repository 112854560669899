import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import Loader from "../../components/Loader";
import { useGetSingleCompany, useGetSport } from "../../shopApi/useProduct";

const ViewCompany = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const query = useQueryClient();
  const { SingleCompany, SingleCompanyLoading, SingleCompanyRefetch } =
    useGetSingleCompany(id);
  const { Sport, SportRefetch } = useGetSport({ page: 1, limit: 100 });

  useEffect(() => {
    SingleCompanyRefetch();
    SportRefetch();
  }, [id]);

  

  return (
    <Sidebar>
      <Header />
      {SingleCompanyLoading ? (
        <Loader />
      ) : (
        <div className="p-6">
          <div
            className="w-8 h-8 bg-customBlue rounded-full text-customWhite flex justify-center items-center cursor-pointer"
            onClick={() => {
              Navigate(-1);
              query.clear();
            }}
          >
            <FaAngleLeft />
          </div>
          <div className="w-3/4 mx-auto bg-customLightGray p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Company Details</h2>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm ">Club Name:</p>
              <p className="text-sm">{SingleCompany?.club_name}</p>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm">Website:</p>
              <p className="text-sm">{SingleCompany?.website}</p>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm">From Day:</p>
              <p className="text-sm">{SingleCompany?.from_day}</p>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm">To Day:</p>
              <p className="text-sm">{SingleCompany?.to_day}</p>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm">No of Teams:</p>
              <p className="text-sm">{SingleCompany?.no_of_teams}</p>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm">From Time:</p>
              <p className="text-sm">
                {moment(SingleCompany?.from_time, "hh:mm:ss A").format(
                  "hh:mm:ss A"
                )}
              </p>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <p className="font-medium text-sm">To Time:</p>
              <p className="text-sm">
                {moment(SingleCompany?.to_time, "hh:mm:ss A").format(
                  "hh:mm:ss A"
                )}
              </p>
            </div>
            {/* <div className="mb-4">
            <label className="font-medium text-sm">Sport:</label>
            <p className="text-sm">{Sport.sports.find(sport => sport.id === SingleCompany.sport?.id)?.sport_name || 'N/A'}</p>
          </div> */}
            <div className="mb-4 ">
              <p className="font-medium text-sm">Profile Image:</p>
             {SingleCompany?.profile_image?.url ? <img
                src={SingleCompany?.profile_image?.url}
                alt="Profile"
                className="w-32 h-32 mt-2 object-cover rounded-full"
              /> :"NAN"}
            </div>
            <div className="mb-4">
              <p className="font-medium text-sm">Cover Image:</p>
              {SingleCompany?.cover_image?.url ? <img
                src={SingleCompany?.cover_image?.url}
                alt="Cover"
                className="w-full mt-2 h-36 object-cover"
              />:"NAN"}
            </div>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default ViewCompany;
